import React, { Component } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { Button, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../../assets/admin/logo_kafood_4.png'
import { faBars, faUser, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listUser: [],
      user: {

      }, show: false,
      dateInput: {
        id_user: "",
        user_name: "",
        user_account: "",
        password: "",
        id_role: "", is_meal: 0
      },
      listrole: [],
      showAlarm: false,
      ingredent_alarmlist: [],
      count: 0,
    }
  }

  sidenavToggle = (event) => {
    document.getElementById('sb-nav-fixed_').classList.toggle('sb-sidenav-toggled');
  }

  logout = (event) => {
    swal({
      title: "Bạn muốn đăng xuất?",
      icon: "warning",
      buttons: ['Đóng', 'Đồng ý'],
      successMode: true,
    })
      .then((ok) => {
        if (ok) {
          localStorage.removeItem("auth_id");
          localStorage.removeItem("auth_full_name");
          localStorage.removeItem("role_id");
          localStorage.removeItem("email");
          localStorage.removeItem("token_type");
          localStorage.removeItem("access_token");

          this.props.navHook('/dang-nhap');

          toast('Đăng xuất thành công', { type: "success" });
        }
      });
  }


  render() {
    return (
      // sb-topnav navbar navbar-expand navbar-dark bg-dark
      <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
        <Link className="navbar-brand_admin ps-4" to="/admin/">
          {/* <span className='text-warning'>Ka</span><span className='text-info'>food</span> */}
          <img src={Logo} style={{ paddingTop: '9px' }} alt="Kafood" />
          {/* VP LIFE */}
        </Link>

        {/* <Link className="navbar-brand ps-3 fw-bold" style={{ 'fontFamily': 'cursive' }} to="/admin">
          <span className='text-warning'>Ka</span><span className='text-info'>food</span>
        </Link> */}
        <Button variant='outline-light' size="sm" className="" id="sidebarToggle" onClick={this.sidenavToggle}>
          <FontAwesomeIcon className='text-white' icon={faBars} />
        </Button>

        <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
        </form>

        <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
          <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle text-white" id="navbarDropdown" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <FontAwesomeIcon icon={faUser} />&nbsp;
              {localStorage.getItem('auth_full_name')}
            </Link>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
              {/* <li><Link className="dropdown-item" onClick={this.handleShow} ><i className="fa-solid fa-gear"> </i> Thông tin</Link></li> */}
              {/* <li><hr className="dropdown-divider" /></li> */}
              <li><Link className="dropdown-item text-danger" onClick={this.logout}>
                <FontAwesomeIcon icon={faRightFromBracket} />&nbsp;

                Đăng xuất</Link></li>
            </ul>
          </li>
        </ul>

      </nav>

    );
  }
}


function myParams(Component) { //Back link AND use "params"
  return props => <Component navHook={useNavigate()} {...props} params={useParams()} />;
}

export default myParams(Navbar);

