import React from "react";
// import "./detail.scss";
import "./about.css";
import { useNavigate } from "react-router-dom";

import { Col, Row, Button } from "react-bootstrap";
// src: "assets/user/images/product/detail/TInh khau phan an.jfif",

const FunctionHome = () => {
  const navigate = useNavigate();
  return (
    <>
      <Row className="justify-content-center align-items-center flex-md-row-reverse mb-2">
        <Col md={7} xs={12} className="text-md-left text-center">
          <div className="funtion-title">
            Tính khẩu phần ăn và biểu mẫu báo cáo tự động
          </div>
          <ul className="function-ul">
            <li>Lên thực đơn hàng tuần, sao chép thực đơn tuần cũ</li>
            <li>
              Cân đối khẩu phần dinh dưỡng đảm bảo năng lượng và tỷ lệ P L G
            </li>
            <li>Dự kiến phiếu đi chợ hàng ngày, xuất định lượng cho bếp nấu</li>
            <li>
              Tự động các sổ sách báo cáo bán trú như sổ 3 bước, sổ lưu mẫu, sổ
              chợ, xuất kho, nhập kho...
            </li>
          </ul>

          <div className="d-flex mb-2 bnt-xct">
            <Button
              onClick={() =>
                navigate("/tinh-nang-phan-mem-quan-ly-suat-an-kafood")
              }
            >
              Xem chi tiết
            </Button>
          </div>
        </Col>
        <Col md={5} xs={12} className="mb-3 mb-md-0">
          <img
            className="partner-img"
            src="assets/user/images/product/detail/TInh khau phan an.jfif"
            width="100%"
            alt="#"
          />
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center mb-2">
        <Col md={7} xs={12} className="text-md-left text-center">
          <div className="funtion-title">Quản lý suất ăn hoàn trả tiền ăn</div>
          <ul className="function-ul">
            <li>Báo cắt suất ăn học sinh chính xác, lưu lại lịch sử</li>
            <li>Thống kê sỉ số hàng ngày, chấm cơm học sinh hàng tháng</li>
            <li>Tính số tiền hoàn trả khi học sinh nghỉ học</li>
            <li>Phụ huynh và giáo viên cắt suất ăn học sinh</li>
          </ul>

          <div className="d-flex bnt-xct mb-2">
            <Button
              onClick={() =>
                navigate("/tinh-nang-phan-mem-quan-ly-suat-an-kafood")
              }
            >
              Xem chi tiết
            </Button>
          </div>
        </Col>
        <Col md={5} xs={12} className="mb-3 mb-md-0">
          <img
            className="partner-img"
            src="assets/user/images/product/detail/Cat suat an.jpg"
            width="100%"
            alt="#"
          />
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center flex-md-row-reverse mb-2">
        <Col md={7} xs={12} className="text-md-left text-center">
          <div className="funtion-title">Quản lý kho hàng và nhà cung cấp</div>
          <ul className="function-ul">
            <li>Quản lý xuất nhập tồn theo lô và hạn sử dụng</li>
            <li>Cảnh báo hàng hết hạn lịch sử xuất kho nhập kho</li>
            <li>
              Lịch sử mua hàng và so sánh giá cả lên xuống các nhà cung cấp khác
              nhau
            </li>
            <li>Báo cáo chi phí mua hàng nhà cung cấp</li>
          </ul>

          <div className="d-flex bnt-xct mb-2">
            <Button
              onClick={() =>
                navigate("/tinh-nang-phan-mem-quan-ly-suat-an-kafood")
              }
            >
              Xem chi tiết
            </Button>
          </div>
        </Col>
        <Col md={5} xs={12} className="mb-3 mb-md-0">
          <img
            className="partner-img"
            src="assets/user/images/product/detail/Hinh anh quan ly kho.jpg"
            width="100%"
            alt="#"
          />
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center mb-2">
        <Col md={7} xs={12} className="text-md-left text-center">
          <div className="funtion-title">
            Quản lý học sinh và thanh toán học phí
          </div>
          <ul className="function-ul">
            <li>Danh sách học sinh theo chuẩn cơ sở dữ liệu quốc gia</li>
            <li>Chuyển lớp, sắp lớp cho học sinh, lưu lịch sử học sinh</li>
            <li>Quản lý các môn học năng khiếu và lịch học các lớp</li>
            <li>
              Thanh toán học phí qua QR code, đối soát tiền học phí tự động
            </li>
          </ul>

          <div className="d-flex bnt-xct mb-2">
            <Button
              onClick={() =>
                navigate("/tinh-nang-phan-mem-quan-ly-suat-an-kafood")
              }
            >
              Xem chi tiết
            </Button>
          </div>
        </Col>
        <Col md={5} xs={12} className="mb-3 mb-md-0">
          <img
            className="partner-img"
            src="assets/user/images/product/detail/thanh toan hoc phi minh hoa.jpg"
            width="100%"
            alt="#"
          />
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center flex-md-row-reverse mb-2">
        <Col md={7} xs={12} className="text-md-left text-center">
          <div className="funtion-title">App Kafood sử dụng cho phụ huynh</div>
          <ul className="function-ul">
            <li>Mobile app Sử dụng trên nền tảng Android và IOS</li>
            <li>Phụ huynh tự cắt suất ăn báo nghỉ học lưu lại lịch sử</li>
            <li>
              Thông báo học phí đến phụ huynh và thanh toán học phí trên app
            </li>
            <li>Phụ huynh đánh giá giáo viên và chất lượng giáo dục</li>
            <li>
              Xem thực đơn hàng ngày hàng tuần và theo dõi dinh dưỡng học sinh
            </li>
            <li>Sổ sức khỏe, Sổ liên lạc, Thời khóa biểu hoạt động học tập.</li>
            <li>
              Sổ nhận thuốc, lịch sử tiêm ngừa, báo món ăn dị ứng. Và còn nhều
              tính năng khác giúp trường quảng bá chất lượng giáo dục đến phụ
              huynh học sinh.
            </li>
          </ul>

          <div className="d-flex bnt-xct mb-2">
            <Button
              onClick={() =>
                navigate("/tinh-nang-phan-mem-quan-ly-suat-an-kafood")
              }
            >
              Xem chi tiết
            </Button>
          </div>
        </Col>
        <Col md={5} xs={12} className="mb-3 mb-md-0">
          <a href="#download-app" style={{ display: "block", width: "100%" }}>
            <img
              className="partner-img"
              src="assets/user/images/product/detail/app tai xuong.jpg"
              width="100%"
              alt="Download App"
            />
          </a>
        </Col>
      </Row>
    </>
  );
};

export default FunctionHome;
