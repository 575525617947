import { useEffect } from "react";
import { Badge, Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import kafoodProductImage from "../../../assets/user/images/kafood-product.png";
import { functions, kafoodServicePacks } from "./data";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProductIndex = () => {
  useEffect(() => {
    document.title = "Kafood | Sản phẩm";
  }, []);

  const navigate = useNavigate();
  const checkout = async (servicePackage) => {
    try {
      localStorage.removeItem("servicePackage");
      localStorage.setItem("servicePackage", JSON.stringify(servicePackage));
      navigate("/dang-ky");
    } catch (error) {
      console.log(error.message);
    }
  };

  const getColorForServicePackage = (servicePackage) => {
    const colorMap = {
      "Cơ bản": "info",
      "Cao cấp": "primary",
      "Chuyên nghiệp": "danger",
      default: "secondary",
    };

    return colorMap[servicePackage] || colorMap.default;
  };

  return (
    <div className="container">
      <div className="text_title">
        <h1>SẢN PHẨM</h1>
      </div>

      <div className="subtitle">
        Quý khách thanh toán đăng ký gói phần mềm vui lòng chuyển khoản theo số
        Tài khoản công ty cổ phần công nghệ Katec:
        <strong className="text-primary"> 119602181010 </strong>- Ngân hàng
        Vietinbank Chi nhánh Cần Thơ
      </div>

      <Row>
        {kafoodServicePacks.map((servicePackage, index) => (
          <Col key={index} md={4} sm={6} xs={12}>
            <Card className="mb-4">
              <div className="image-container">
                <Card.Img
                  variant="top"
                  src={kafoodProductImage}
                  alt={servicePackage.name}
                />
              </div>
              <Card.Body>
                <Card.Title>
                  <Badge
                    pill
                    bg={getColorForServicePackage(servicePackage.name)}
                  >
                    {servicePackage.name}
                  </Badge>
                </Card.Title>

                <ul className="list-unstyled text-muted vstack gap-3 ff-secondary mt-4 mb-4">
                  {functions.map((func, index) => (
                    <li key={index}>
                      <div className="d-flex">
                        <div className="flex-shrink-0 text-success me-3">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="fs-15 align-middle"
                          />
                        </div>
                        <div className="flex-grow-1">{func.name}</div>
                      </div>
                    </li>
                  ))}
                </ul>

                <Card.Text>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      Số lượng học sinh:{" "}
                      <span
                        className={`text-${getColorForServicePackage(
                          servicePackage.name
                        )}`}
                      >
                        {servicePackage.totalStudent}
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Số lượng nhân viên:{" "}
                      <span
                        className={`text-${getColorForServicePackage(
                          servicePackage.name
                        )}`}
                      >
                        {servicePackage.totalEmployees}
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Giá:{" "}
                      <span
                        className={`text-${getColorForServicePackage(
                          servicePackage.name
                        )}`}
                      >
                        <strong className="fs-5">{servicePackage.price}</strong>
                      </span>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Text>

                <div className="text-center">
                  <Button
                    variant="primary"
                    onClick={() => checkout(servicePackage)}
                  >
                    Đăng ký gói
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ProductIndex;
