import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEyeSlash,
  faEye,
  faLock,
  faRightToBracket,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./Login.scss";

const Login = () => {
  const [loginInput, setLoginInput] = useState({
    username: "",
    password: "",
  });

  const [isShowPassword, setIsShowPassword] = useState(true);

  useEffect(() => {
    document.title = "Đăng nhập";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const hanldeLogin = async (e) => {
    e.preventDefault();

    localStorage.removeItem("auth_id");
    localStorage.removeItem("auth_full_name");
    localStorage.removeItem("role_id");
    localStorage.removeItem("email");
    localStorage.removeItem("token_type");
    localStorage.removeItem("access_token");

    let dataLogin = new FormData();
    dataLogin.append("username", loginInput.username);
    dataLogin.append("password", loginInput.password);

    await axios({
      method: "post",
      url: "login",
      data: dataLogin,
    })
      .then((res) => {
        if (res.data.errCode === 0) {
          localStorage.setItem("auth_id", res.data.userData.id);
          localStorage.setItem("auth_full_name", res.data.userData.fullName);
          localStorage.setItem("role_id", res.data.userData.roleId);
          localStorage.setItem("email", res.data.userData.email);
          localStorage.setItem("token_type", res.data.tokenType);
          localStorage.setItem("access_token", res.data.accessToken);

          toast(res.data.message, { type: "success", autoClose: 1000 });

          window.location.href = "/admin";
        } else {
          toast(res.data.message, { type: "error", autoClose: 1000 });
        }
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      });
  };

  const handleOnChangeInput = (e) => {
    let field = e.target.name;
    let value = e.target.value;

    let data = { ...loginInput };

    data[field] = value;

    setLoginInput(data);
  };

  return (
    <div className="container mb-5 login-background">
      <Row className="justify-content-center">
        <Col md={6} sm={12}>
          <div className="text_title">
            <h1>ĐĂNG NHẬP</h1>
          </div>
          <Form
            onSubmit={(e) => hanldeLogin(e)}
            method="post"
            autoComplete="off"
          >
            <Form.Floating className="mb-3">
              <Form.Control
                onChange={(e) => handleOnChangeInput(e)}
                value={loginInput.username}
                type="text"
                placeholder="Tài khoản"
                name="username"
                autoComplete="off"
              />
              <Form.Label>
                <FontAwesomeIcon icon={faUser} /> Tài khoản
              </Form.Label>
            </Form.Floating>

            <Form.Floating className="mb-3  input-group">
              <Form.Control
                onChange={(e) => handleOnChangeInput(e)}
                value={loginInput.password}
                type={isShowPassword ? "password" : "text"}
                placeholder="Mật khẩu"
                name="password"
                autoComplete="new-password"
              />

              <InputGroup.Text
                className="btn-eye"
                onClick={() => setIsShowPassword(!isShowPassword)}
                style={{ width: "3rem" }}
              >
                <FontAwesomeIcon icon={isShowPassword ? faEyeSlash : faEye} />
              </InputGroup.Text>
              <Form.Label>
                <FontAwesomeIcon icon={faLock} /> Mật khẩu
              </Form.Label>
            </Form.Floating>

            <center>
              <Button type="submit">
                <FontAwesomeIcon icon={faRightToBracket} /> Đăng nhập
              </Button>
            </center>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
