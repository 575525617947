import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import '../../assets/user/css/style.css';
import '../../assets/user/css/responsive.css';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons'
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons'
import NavbarHome from './Navbar';
import Footer from './Footer';
import { toast } from 'react-toastify';
import axios from 'axios';


const MasterLayout = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getTopics();
    }, []);

    const [topics, setTopics] = useState([]);

    const getTopics = async () => {
        await axios.get(`/getTopics`).then((res) => {
            if (res.data.errCode === 0) {
                setTopics(res.data.topics);
            } else {
                // toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            // toast(error.message, { type: 'error' });
        });
    }

    const top_up = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const sidenavToggle = () => {
        const navbarSupportedContent = document.getElementById('navbarSupportedContent');

        let testClass = navbarSupportedContent.className;

        if (testClass === 'navbar-collapse collapse show' && window.innerWidth < 992) {
            navbarSupportedContent.classList.remove('show');
        }
    }

    return (
        <div className='sb-nav-fixed' id="sb-nav-fixed">
            <NavbarHome topics={topics} />

            <div id="layoutSidenav" onClick={sidenavToggle}>
                <div id="layoutSidenav_content" >

                    <main>
                        <Outlet />

                        <div style={{
                            display: 'inline-block', marginTop: '300px',
                            position: 'fixed',
                            bottom: ' 70px',
                            right: '-100px',
                            opacity: 0.8,
                        }}>
                            <Link to="tel: 0849881010" className='call-icon' title='Hotline: 0849881010'>
                                <div className="wrapper">
                                    <div className="ring">
                                        <div className="coccoc-alo-phone coccoc-alo-green coccoc-alo-show">
                                            <div className="coccoc-alo-ph-circle"></div>
                                            <div className="coccoc-alo-ph-circle-fill"></div>
                                            <div className="coccoc-alo-ph-img-circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </Link>

                            <br></br>

                            <Link to={"https://www.messenger.com/t/118083051317668"} target='_blank' className="mes" title='Nhắn tin FaceBook'>
                                <FontAwesomeIcon icon={faFacebookMessenger} size='2xl' style={{
                                    height: '40px', width: '40px', marginLeft: '-10px', color: 'blue !important', opacity: 0.9
                                }} shake />

                                <span></span>
                            </Link>
                            <br></br>
                            {/* <Link to='#' title="Back to top">
                                <FontAwesomeIcon icon={faArrowAltCircleUp} size='2xl' style={{ textAlign: 'center', height: '40px', width: '40px', color: 'green', marginLeft: '-10px', }} onClick={() => top_up()} />
                            </Link> */}
                            <div title="Về đầu trang" id="top-up" onClick={top_up}>
                                <FontAwesomeIcon icon={faArrowAltCircleUp} size='2xl' style={{ textAlign: 'center', height: '40px', width: '40px', color: 'green', marginLeft: '-50px', }} /></div>
                            <br />
                        </div>
                    </main>
                    <Footer />
                </div>
            </div >
        </div >
    );
}

function myParams(Component) { //Back link AND use "params"
    return props => <Component navHook={useNavigate()} />;
}

export default myParams(MasterLayout);
