
import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import emailjs from '@emailjs/browser'
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Modal, Row, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';
import "./home.scss";
const ContactForm = (props) => {
    const form = useRef();
    const navigate = useNavigate();

    const sendEmail = (e) => {
        if (form.current.phone.value.toString().length > 9) {
            emailjs.sendForm('service_fihg5vx', 'template_es4vjjf', form.current, 'tt8EBINZkDg6uPMLi')
                .then(() => {
                    navigate("/dang-ky-thanh-cong");
              
                    toast(`Đăng ký thành công`, { type: "success", autoClose: 1000, style: { marginTop: '50px' } });
                }, (error) => { console.log(error.text) })
        }
        else {
            toast(`Vui lòng nhập số điện thoại`, { type: "error", autoClose: 1000, style: { marginTop: '50px' } });
        }
    }

   
    return (
      

                <Form 
                ref={form} onSubmit={() => sendEmail()}>
                    <Row>
                     
                        <Col>
                            <Form.Floating className="mb-3">
                                <Form.Control type="text" name='user_name' placeholder="Tên của bạn" required className='form-contact' />
                                <Form.Label >
                                    Tên của bạn<i className="text-danger"></i>
                                </Form.Label>
                                <div className="invalid-feedback"></div>
                            </Form.Floating>
                        </Col>
                       
                    </Row>
                    <Form.Floating className="mb-3">
                                <Form.Control type="text" name='phone' placeholder="Số điện thoại" required
                                 className='form-contact'
                                 />
                                <Form.Label >
                                    Số điện thoại<i className="text-danger">*</i>
                                </Form.Label>
                                <div className="invalid-feedback"></div>
                            </Form.Floating>
                    <Form.Floating className="mb-3">
                        <Form.Control type="text" placeholder="Lời nhắn" name='message' className='form-contact'/>
                        <Form.Label >
                            Lời nhắn
                        </Form.Label>
                        <div className="invalid-feedback"></div>
                    </Form.Floating>
                    <center>
                    <Button  
                    // type="submit"
                     variant="success"
                     onClick={() => sendEmail()  }
                      className='text-center'>
                    <FontAwesomeIcon icon={faPaperPlane} /> Đăng ký tư vấn miễn phí
                </Button>
                    </center>
                 
                </Form>
         
    );
}

export default ContactForm;