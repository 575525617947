export const kafoodServicePacks = [
  {
    packageId: 1,
    name: "Cơ bản",
    price: "150.000 đ/tháng",
    totalStudent: "< 100",
    totalEmployees: "< 10",
  },
  {
    packageId: 2,
    name: "Cao cấp",
    price: "300.000 đ/tháng",
    totalStudent: "Từ 100 - 500",
    totalEmployees: "< 50",
  },
  {
    packageId: 3,
    name: "Chuyên nghiệp",
    price: "600.000 đ/tháng",
    totalStudent: "Không giới hạn",
    totalEmployees: "Không giới hạn",
  },
];

export const functions = [
  {
    name: "Thực đơn.",
  },
  {
    name: "Cân đối khẩu phần dinh dưỡng theo buổi ăn và nhóm trẻ.",
  },
  {
    name: "Định lượng món ăn.",
  },
  {
    name: "Biểu mẫu báo cáo theo qui định.",
  },

  {
    name: "Quản lý suất ăn học sinh.",
  },
  {
    name: "Quản lý kho hàng và xuất nhập tồn.",
  },
  {
    name: "Nhà cung cấp.",
  },

  {
    name: "Quản lý nhân viên và phân quyền",
  },
  {
    name: "Quản lý danh sách học sinh.",
  },
  {
    name: "Báo cáo thống kê.",
  },
];
