import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AB1 from "../../../assets/user/images/ab1.jpg";
import AB2 from "../../../assets/user/images/ab2.jpg";
import AB3 from "../../../assets/user/images/ab3.jpg";
import "./about.css";

const About = () => {
  useEffect(() => {
    document.title = "Kafood | Giới thiệu";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="detail_bg" id="page_detail">
      <div className="text_title">
        <h1> Giới thiệu</h1>
      </div>

      <Row className="m-0">
        <Col md={{ span: 8, offset: 2 }}>
          <Container className="p-lg-5 bg-white">
            <Row className="m-0 mb-2 about-row">
              <Col md={12} className="about-col">
                <p style={{ textAlign: "justify" }}>
                  <span className="text-primary fw-bold">
                    {" "}
                    Công ty Cổ phần Công nghệ Katec
                  </span>
                  , xin trân trọng gửi đến Quý vị lời chào trân trọng nhất.
                </p>
                <div style={{ textAlign: "justify" }}>
                  Với sứ mệnh mang lại những giải pháp công nghệ tiên tiến và
                  thúc đẩy sự phát triển toàn diện trong lĩnh vực dinh dưỡng và
                  chăm sóc sức khỏe, chúng tôi vô cùng tự hào giới thiệu đến Quý
                  vị sản phẩm mới nhất của chúng tôi -{" "}
                  <span className="text-primary fw-bold">
                    "Phần mềm quản lý suất ăn và định lượng dinh dưỡng cho trẻ -
                    Kafood"
                  </span>
                  .
                </div>
              </Col>
            </Row>
            <Row className="m-0 mb-2 about-row">
              <Col md={6} className="mb-2">
                <img src={AB1} alt="" className="about-img " />
                <div
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Giấy chứng nhận doanh nghiệp khoa học và công nghệ
                </div>
              </Col>
              <Col md={6} className="">
                <img src={AB2} alt="" className="about-img " />
                <div
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Giấy chứng nhận đăng ký quyền tác giả
                </div>
              </Col>
            </Row>

            <Row className="about-row ">
              <Col md={12} className="about-col">
                <p style={{ textAlign: "justify" }}>
                  <span className="text-primary fw-bold">Kafood </span>không chỉ
                  là một phần mềm quản lý suất ăn và định lượng dinh dưỡng đơn
                  thuần, mà còn là một công cụ mạnh mẽ hỗ trợ cho việc chăm sóc
                  dinh dưỡng của trẻ mầm non và quản lý hoạt động bán trú hiệu
                  quả nhất. Với sự kết hợp của công nghệ hiện đại và kiến thức
                  sâu rộng về dinh dưỡng, Kafood đã được phát triển với mục tiêu
                  mang lại sự tiện lợi, chính xác và đáng tin cậy cho các phụ
                  huynh và cơ sở giáo dục.
                  <br />
                </p>
                <p style={{ textAlign: "justify" }}>
                  Sự cam kết của chúng tôi không chỉ dừng lại ở việc cung cấp
                  một sản phẩm chất lượng, mà còn là việc đồng hành và hỗ trợ
                  các trường mầm non, nhóm trẻ quản lý hoạt động cơ sở mình đạt
                  hiệu quả tốt nhất.
                  <br />
                </p>
                <p style={{ textAlign: "justify" }}>
                  Kafood được triển khai sử dụng vô cùng dễ dàng và tiện dụng
                  mang lại giá trị thiết thực cho trường và phụ huynh.
                  <br />
                </p>
              </Col>
            </Row>
            <Row className="about-row mb-2">
              <Col md={12} className="about-col">
                <p style={{ textAlign: "justify" }}>
                  Năm 2022-2023 Kafood đạt giải nhì cuộc thi sáng tạo kỹ thuật
                  lần thứ 12 thành phố Cần Thơ (Đài{" "}
                  <a
                    href="https://canthotv.vn/noi-hoi-tu-va-ton-vinh-su-sang-tao-2/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span style={{ fontSize: "1.2rem" }}>
                      https://canthotv.vn/noi-hoi-tu-va-ton-vinh-su-sang-tao-2).
                    </span>
                  </a>
                </p>
                <p style={{ textAlign: "justify" }}>
                  Và được các trường mầm non, nhóm trẻ đánh giá đạt hiệu quả
                  kinh tế cao trong việc khai thác sử dụng (Đài{" "}
                  <a
                    href="https://canthotv.vn/quan-ly-suat-an-va-dinh-luong-dinh-duong-cho-tre/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span style={{ fontSize: "1.2rem" }}>
                      https://canthotv.vn/quan-ly-suat-an-va-dinh-luong-dinh-duong-cho-tre/).
                    </span>
                  </a>
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="about-img-col">
                <center>
                  <img
                    src={AB3}
                    alt=""
                    className="about-img"
                    style={{ width: "50%" }}
                  />
                  <div
                    style={{
                      textAlign: "center",
                      fontStyle: "italic",
                      fontSize: "0.8rem",
                    }}
                  >
                    {" "}
                    Bằng khen thành phố Cần Thơ
                  </div>
                </center>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <p>Trân trọng!</p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default About;
