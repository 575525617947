import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper, faTachometerAlt, faTags, faUsers } from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
  return (
    <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion" >
      <div className="sb-sidenav-menu">
        <div className="nav">
          <NavLink className="nav-link" to="/admin/">
            <div className="sb-nav-link-icon">
              <FontAwesomeIcon icon={faTachometerAlt} />
            </div>
            Tổng quan
          </NavLink>

          <NavLink className="nav-link" to="/admin/post">
            <div className="sb-nav-link-icon">
              <FontAwesomeIcon icon={faNewspaper} />
            </div>
            Bài viết
          </NavLink>

          <NavLink className="nav-link" to="/admin/user">
            <div className="sb-nav-link-icon">
              <FontAwesomeIcon icon={faUsers} />
            </div>
            Thành viên
          </NavLink>

          <NavLink className="nav-link" to="/admin/topic">
            <div className="sb-nav-link-icon">
              <FontAwesomeIcon icon={faTags} />
            </div>
            Chủ đề bài viết
          </NavLink>

        </div>
      </div>
      <div className="sb-sidenav-footer py-2">
        <div className="small">Version: 1.0</div>
      </div>
    </nav>
  );
};

export default Sidebar;
