import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import ContactModal from "../../components/user/home/ContactModal";

// import '../../assets/admin/css/style.css';
const NavbarHome = (props) => {
  const [showContactModal, setShowContactModal] = useState(false);

  const sidenavToggle = () => {
    const navbarSupportedContent = document.getElementById(
      "navbarSupportedContent"
    );

    let testClass = navbarSupportedContent.className;

    if (
      testClass === "navbar-collapse collapse show" &&
      window.innerWidth < 992
    ) {
      navbarSupportedContent.classList.remove("show");
    }
  };

  const topicItem = () => {
    if (props.topics.length > 0) {
      return props.topics
        ?.filter((e) => e.slug !== "chinh-sach")
        .map((item, i) => {
          return (
            <NavDropdown.Item
              key={`topicItem${i}`}
              as={NavLink}
              to={`/chu-de/${item.slug}`}
              onClick={sidenavToggle}
            >
              {item.nameVi}
            </NavDropdown.Item>
          );
        });
    }
  };

  return (
    <header id="header" className="top-head">
      <div className="hotline">
        <a href="tel: 0849881010">
          <FontAwesomeIcon icon={faPhone} /> Liên hệ : 0889881010 - 0849881010
        </a>
        <div className="icon_">
          <ul className="list-inline socials">
            <li className="list-inline-item px-1">
              <Link to={"https://www.facebook.com/kateccantho"} target="_blank">
                <FontAwesomeIcon icon={faFacebookF} />
              </Link>
            </li>

            <li className="list-inline-item px-1">
              <Link
                to={"https://zalo.me/0849881010"}
                className="fw-bold"
                target="_blank"
              >
                Zalo
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <Navbar collapseOnSelect expand="md" bg="light" variant="light">
        <Container fluid>
          <Link className="navbar-brand" to={"/"}>
            <img
              src="../../assets/user/images/logo_kafood_3.png"
              alt="katec_logo"
            />
          </Link>
          <button
            className="button-85 px-3"
            type="button"
            onClick={() => setShowContactModal(true)}
          >
            Đăng ký dùng thử
          </button>
          <pre> </pre>
          <Navbar.Toggle aria-controls="navbarSupportedContent" />

          <Navbar.Collapse id="navbarSupportedContent">
            <Nav className="ms-auto">
              <Nav.Link as={NavLink} to={"/"} onClick={sidenavToggle}>
                Trang chủ
              </Nav.Link>
              <Nav.Link as={NavLink} to={"/gioi-thieu"} onClick={sidenavToggle}>
                Giới thiệu
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to={"/tinh-nang-phan-mem-quan-ly-suat-an-kafood"}
                onClick={sidenavToggle}
              >
                Tính năng
              </Nav.Link>
              <Nav.Link as={NavLink} to={"/bang-gia"} onClick={sidenavToggle}>
                Bảng giá
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to={"/dang-ky-goi"}
                onClick={sidenavToggle}
              >
                Đăng ký gói Phần mềm
              </Nav.Link>

              <NavDropdown title="Góc chia sẻ" id="collasible-nav-dropdown">
                <NavDropdown.Item
                  as={NavLink}
                  to={"/goc-chia-se"}
                  onClick={sidenavToggle}
                >
                  Tất cả
                </NavDropdown.Item>
                {topicItem()}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {showContactModal ? (
        <ContactModal
          show={showContactModal}
          onHide={() => setShowContactModal(false)}
        />
      ) : null}
    </header>
  );
};

export default NavbarHome;
