import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Image,
  ListGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { months } from "./data";
import schema from "./schema";

const CheckoutIndex = () => {
  const [selectedPackage, setSelectedPackage] = useState(12);
  const [qrCodePayment, setQRCodePayment] = useState("");
  const servicePackage = JSON.parse(localStorage.getItem("servicePackage"));
  const [isLoading, setIsLoading] = useState(false);
  const [qrLoading, setQRLoading] = useState(false);
  const navigate = useNavigate();

  const genQRCode = useCallback(async () => {
    setQRLoading(true);
    try {
      const months = selectedPackage;
      const response = await axios.post("genQR", {
        packageId: servicePackage.packageId,
        months,
      });

      setQRCodePayment(response.data.data.qrDataURL);
      setQRLoading(false);
    } catch (error) {
      console.log(error.message);
      setQRLoading(false);
    }
  }, [selectedPackage, servicePackage?.packageId]);

  const loadRecaptchaScript = () => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_SITE_KEY}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  };

  useEffect(() => {
    document.title = "Katec | Đặt hàng";
    genQRCode();
    loadRecaptchaScript();
  }, [selectedPackage, genQRCode]);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      const recaptchaToken = await window.grecaptcha.execute(
        `${process.env.REACT_APP_GOOGLE_SITE_KEY}`,
        {
          action: "submit",
        }
      );

      const response = await axios.post("/order/create", {
        ...data,
        packageId: servicePackage.packageId,
        token: recaptchaToken,
      });
      setIsLoading(false);
      toast.success(response.data.message);
      navigate("/dang-ky-thanh-cong");
      localStorage.removeItem("servicePackage");
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message);
      reset();
    }
  };

  return (
    <div className="container mb-3">
      {servicePackage ? (
        <>
          <div className="text_title">
            <h1>ĐĂNG KÝ GÓI</h1>
          </div>

          <Form onSubmit={handleSubmit((data) => onSubmit(data, reset))}>
            <Row>
              <Col md={8}>
                <ListGroup as="ul" className="mb-4">
                  {months.map((month, index) => (
                    <ListGroup.Item
                      key={index}
                      as="li"
                      className="d-flex justify-content-between align-items-center mb-1 pointer"
                      role="button"
                      onClick={() => setSelectedPackage(month)}
                      active={month === selectedPackage}
                      disabled={isLoading}
                    >
                      <div>
                        <div className="fw-bold">
                          Gói: {servicePackage?.name} - {month} tháng
                        </div>
                        <span>
                          Số lượng học sinh: {servicePackage?.totalStudent} - Số
                          lượng Nhân viên: {servicePackage?.totalEmployees}
                        </span>
                      </div>
                      <Badge
                        bg={month === selectedPackage ? "light" : "primary"}
                        text={month === selectedPackage ? "dark" : undefined}
                      >
                        {month} x {servicePackage?.price}
                      </Badge>
                    </ListGroup.Item>
                  ))}
                </ListGroup>

                <Card className="mb-3">
                  <Card.Title className="mx-3 mt-3">
                    Thông tin người mua hàng
                  </Card.Title>
                  <Row className="mx-2 my-3">
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="name">
                        <Form.Label>
                          Họ và tên<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          autoFocus
                          type="text"
                          placeholder="Nhập họ và tên"
                          {...register("name", { required: true })}
                        />
                        {errors.name && (
                          <span className="text-danger">
                            {errors.name.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="address">
                        <Form.Label>
                          Địa chỉ<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Nhập địa chỉ"
                          {...register("address", { required: true })}
                        />
                        {errors.address && (
                          <span className="text-danger">
                            {errors.address.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="email">
                        <Form.Label>
                          Địa chỉ email<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Nhập địa chỉ email"
                          {...register("email", { required: true })}
                        />
                        {errors.email && (
                          <span className="text-danger">
                            {errors.email.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="phoneNumber">
                        <Form.Label>
                          Số điện thoại<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Nhập số điện thoại"
                          {...register("phoneNumber", { required: true })}
                        />
                        {errors.phoneNumber && (
                          <span className="text-danger">
                            {errors.phoneNumber.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="note">
                        <Form.Label>Ghi chú</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          type="text"
                          placeholder="Nhập ghi chú"
                          {...register("note")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col md={4}>
                <Card>
                  <Card.Title className="mx-3 mt-3">
                    Hình thức thanh toán chuyển khoản
                  </Card.Title>

                  {!qrLoading ? (
                    <Image src={qrCodePayment} className="mb-3 mh-100" />
                  ) : (
                    <div className="d-flex align-items-center justify-content-center">
                      <Spinner size="xl" />
                    </div>
                  )}

                  <div className="text-end mx-3 my-3">
                    <Button disabled={isLoading} type="submit">
                      {isLoading && <Spinner size="sm" />} Đăng ký gói
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        navigate("/dang-ky-goi")
      )}
    </div>
  );
};

export default CheckoutIndex;
