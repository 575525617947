import React, { useEffect } from "react";
import "./detail.scss";
import HeadMeta from "../../other/HeadMeta";
import { Col, Row } from "react-bootstrap";
import Function from "./Function";

const Detail = () => {
  useEffect(() => {
    document.title = "Kafood | Tính năng phần mềm quản lý suất ăn Kafood";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const meta = {
    title: "Kafood | Tính năng phần mềm quản lý suất ăn Kafood",
    description:
      "Quản lý suất ăn học sinh và nhân viên, Thực đơn, Cân đối khẩu phần dinh dưỡng cho trẻ, Quản lý nhân viên và phân quyền, Quản lý danh  sách học sinh, Quản lý kho hàng và xuất nhập tồn, Biểu mẫu báo cáo theo chuẩn phòng sở...",
    keywords: "kafood, phần mềm quản lý suất ăn, kafood.vn",
    og_image:
      process.env.REACT_APP_FRONTEND_URL +
      "/assets/user/images/product/detail/5_5.png",
    og_image_type: "image/png",
    // fb_app_id: '',
  };

  return (
    <div className="detail_bg" id="page_detail">
      <HeadMeta {...meta} />

      <div className="text_title">
        <h1>TÍNH NĂNG PHẦN MỀM QUẢN LÝ SUẤT ĂN KAFOOD</h1>
      </div>
      <Function />
    </div>
  );
};

export default Detail;
