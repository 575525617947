import React from "react";
import "./detail.scss";
import { Col, Row } from "react-bootstrap";

const Function = () => {
  return (
    <Row className="m-0">
      <Col md={{ span: 8, offset: 2 }}>
        <div className="container bg-white">
          <div className="p-lg-5 pt-3">
            <h3 className="fw-bold"> 1. Quản lý giáo viên và phân quyền.</h3>
            <p>
              - Danh sách nhân viên, giáo viên theo chuẩn cơ sở dữ liệu quốc
              gia.
              <br />
              - Phân quyền giáo viên chi tiết theo chức vụ được giao.
              <br />
              -Cấp tài khoản riêng cho giáo viên cắt suất ăn học sinh và xin
              nghỉ phép.
              <img
                src="../../../assets/user/images/product/detail/8_8.png"
                className="icon-small"
                alt=""
                width="100%"
              />
            </p>
            <h3 className="fw-bold"> 2. Quản lý danh sách học sinh.</h3>
            <p>
              - Danh sách học sinh theo chuẩn cơ sở dữ liêu quốc gia.
              <br />
              - Chuyển lớp, sắp lớp cho học sinh.
              <br />
              - Xuất file danh sách học sinh có ngày sinh nhật trong tháng.
              <br />
              - Tạo và quản lý tài khoản phụ huynh.
              <img
                src="../../../assets/user/images/product/detail/2_2.png"
                className="icon-small"
                alt=""
                width="100%"
              />
            </p>
            <h3 className="fw-bold"> 3. Quản lý suất ăn học sinh.</h3>
            <p>
              - Báo và cắt suất ăn học sinh, nhân viên chính xác, nhanh.
              <br />
              - Thống kê sỉ số học sinh, hằng ngày.
              <br />
              - Tính số tiền ăn trả lại cho phụ huynh khi học sinh nghỉ học.
              <br />
              - Lưu lịch sử báo cắt suất ăn của giáo viên.
              <img
                src="../../../assets/user/images/product/detail/5_5.png"
                className="icon-small"
                alt=""
                width="100%"
              />
            </p>

            <h3 className="fw-bold"> 4. Thực đơn.</h3>
            <p>
              - Có sẵn ngân hàng món ăn với hơn 600 món ăn tham khảo.
              <br />
              - Lên thực đơn hàng tuần, hằng ngày nhanh chóng.
              <br />
              - In định lượng cho bếp theo món ăn và nhóm trẻ.
              <br />
              - Xuất file làm sổ thực đơn tuần chi tiết.
              <br />
              - Sao chép thực đơn tuần cũ.
              <img
                src="../../../assets/user/images/product/detail/2.png"
                className="icon-small"
                alt=""
                width="100%"
              />
            </p>

            <h3 className="fw-bold">
              {" "}
              5. Cân đối khẩu phần dinh dưỡng cho trẻ.
            </h3>
            <p>
              - Xác định năng lượng và thành phần dinh dưỡng (Protein, Lipid,
              Gluxit).
              <br />
              - Cân đối khẩu phần ăn theo khuyến nghị viện dinh dưỡng, tính thu
              chi chênh lệch tiền ăn.
              <br />
              - Lên dự kiến phiếu đi chợ hàng ngày.
              <br />
              - Xuất file báo cáo hóa đơn đi chợ, kết quả dưỡng chất, và thiết
              lập dưỡng chất hằng ngày.
              <img
                src="../../../assets/user/images/product/detail/1_1.png"
                className="icon-small"
                alt=""
                width="100%"
              />
            </p>

            <h3 className="fw-bold"> 6. Quản lý kho hàng và xuất nhập tồn.</h3>
            <p>
              - Phân loại nguyên vật liệu theo hàng chợ, hàng kho, vật tư.
              <br />
              - Quản lý xuất nhập tồn theo lô và hạn sử dụng.
              <br />
              - Cảnh báo hàng hết hạn, lưu lại lịch sử xuất nhập kho.
              <br />
              - Phiếu nhập - xuất kho theo qui định.
              <img
                src="../../../assets/user/images/product/detail/6_6.png"
                className="icon-small"
                alt=""
                width="100%"
              />
            </p>

            <h3 className="fw-bold"> 7. Nhà cung cấp.</h3>
            <p>
              - Danh sách nhà cung cấp và lịch sử nhập hàng.
              <br />
              - Kiểm tra giá cả lên xuống theo mặt hàng và so sánh giá của các
              nhà cung cấp khác nhau.
              <br />
              - Báo cáo chi phí mua hàng theo nhà cung cấp.
              <img
                src="../../../assets/user/images/product/detail/7_7.png"
                className="icon-small"
                alt=""
                width="100%"
              />
            </p>

            <h3 className="fw-bold">
              {" "}
              8. Báo cáo dinh dưỡng, danh sách đi chợ và định lượng bếp
            </h3>
            <p>
              - Thống kê dinh dưỡng ngày, tuần, tháng.
              <br />
              - Kiểm tra dưỡng chất của trẻ có đạt khuyến nghị chưa. <br />-
              Cung cấp phiếu đi chợ đúng với dinh dưỡng đã tính khẩu phần ăn.{" "}
              <br />
              - Giúp bộ phận bếp nấu ăn đúng với định lượng khi đi chợ. <br />
              <img
                src="../../../assets/user/images/product/detail/4.png"
                className="icon-small"
                alt=""
                width="100%"
              />
            </p>
            <h3 className="fw-bold"> 9. Quản lý môn học và học phí.</h3>
            <p>
              - Tạo danh sách nhóm và môn học.
              <br />
              - Tạo danh sách khoản thu và phiếu thu.
              <br />
              - Quản lý học phí của từng môn học và lịch sử thu tiền.
              <br />
              <img
                src="../../../assets/user/images/product/detail/pt.jpg"
                className="icon-small"
                alt=""
                width="100%"
              />
            </p>

            <h3 className="fw-bold"> 10. Tạo phiếu đánh giá giáo viên.</h3>
            <p>
              - Trường chủ động tạo ra tiêu chí đánh giá giáo viên thông qua
              app.
              <br />
              <img
                src="../../../assets/user/images/product/detail/rate.jpg"
                className="icon-small"
                alt=""
                width="100%"
              />
            </p>

            <h3 className="fw-bold"> 11. Biểu mẫu báo cáo theo qui định.</h3>
            <p>
              - Sổ kiểm thực 3 bước.
              <br />
              - Sổ lưu mẫu ngày.
              <br />
              - Sổ tài chính công khai.
              <br />
              - Sổ thực đơn tuần.
              <img
                src="../../../assets/user/images/product/detail/3_3.png"
                className="icon-small"
                alt=""
                width="100%"
              />
            </p>

            <h3 className="fw-bold">
              12. Có app sử dụng cho Phụ huynh (Kafood).
            </h3>
            <p>
              App Kafood sử dụng trên điện thoại có các tính năng sau:
              <br />- Sổ sức khỏe.
              <br />- Thành tích học tập.
              <br />- Thời khóa biểu hoạt động học tập.
              <br />- Năng lượng trẻ ngày, tuần, tháng.
              <br />- Thực đơn theo ngày, tuần, tháng
              <br />- Báo cắt suất ăn, nghỉ học.
              <br />- Đánh giá giáo viên. Báo nhà trường món ăn bị dị ứng.
              <br />- Sổ kí nhận thuốc, lịch sử tiêm ngừa.
              <br />- Kho hình ảnh hoạt động.
              <br />- Thanh toán học phí trực tiếp trên app, web.
              <img
                src="../../../assets/user/images/product/detail/12.png"
                className="icon-small"
                alt=""
                width="100%"
              />
              <p className="text-center fw-bold">
                App sử dụng trên nền tảng Android và IOS
              </p>
              <center>
                <img
                  src="../../../assets/user/images/product/detail/12_12.jpg"
                  className="icon-small"
                  alt=""
                  width="40%"
                />
              </center>
            </p>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Function;
