
import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import emailjs from '@emailjs/browser'
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Modal, Row, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';

const ContactModal = (props) => {
    const form = useRef();
    const navigate = useNavigate();

    const sendEmail = (e) => {
        if (form.current.phone.value.toString().length > 9) {
            emailjs.sendForm('service_fihg5vx', 'template_es4vjjf', form.current, 'tt8EBINZkDg6uPMLi')
                .then(() => {
                    navigate("/dang-ky-thanh-cong");
                    formClose();
                    toast(`Đăng ký thành công`, { type: "success", autoClose: 1000, style: { marginTop: '50px' } });
                }, (error) => { console.log(error.text) })
        }
        else {
            toast(`Vui lòng nhập số điện thoại`, { type: "error", autoClose: 1000, style: { marginTop: '50px' } });
        }
    }

    const formClose = () => {
        const { onHide } = { ...props };
        return onHide();
    }

    return (
        <Modal show={props.show} onHide={formClose} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton className='fw-bold'>
                Liên hệ với chúng tôi
            </Modal.Header>
            <Modal.Body>

                <Form ref={form} onSubmit={() => sendEmail()}>
                    <Row>
                        <p className='text-muted text-start'>
                            Hãy để lại thông tin. Kafood sẽ liên hệ với bạn trong thời gian sớm nhất!
                        </p>
                        <Col>
                            <Form.Floating className="mb-3">
                                <Form.Control type="text" name='user_name' placeholder="Tên của bạn" required />
                                <Form.Label >
                                    Tên của bạn<i className="text-danger">*</i>
                                </Form.Label>
                                <div className="invalid-feedback"></div>
                            </Form.Floating>
                        </Col>
                        <Col>
                            <Form.Floating className="mb-3">
                                <Form.Control type="text" name='phone' placeholder="Số điện thoại" required />
                                <Form.Label >
                                    Số điện thoại<i className="text-danger">*</i>
                                </Form.Label>
                                <div className="invalid-feedback"></div>
                            </Form.Floating>
                        </Col>
                    </Row>

                    <Form.Floating className="mb-3">
                        <Form.Control type="text" placeholder="Lời nhắn" name='message' />
                        <Form.Label >
                            Lời nhắn
                        </Form.Label>
                        <div className="invalid-feedback"></div>
                    </Form.Floating>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => formClose()} >
                    <FontAwesomeIcon icon={faTimes} /> Đóng
                </Button>
                <Button type="submit" variant="success" onClick={() => sendEmail()} >
                    <FontAwesomeIcon icon={faPaperPlane} /> Gửi
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ContactModal;