import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Form, Row, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import Accordion from "react-bootstrap/Accordion";
import Carousel from "react-bootstrap/Carousel";
import { faCircleDot } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cost from "./Cost";
import "./home.scss";
import HeadMeta from "../../other/HeadMeta";
import ContactModal from "./ContactModal";
import ContactForm from "./ContactForm";
import Imga from "../../../assets/user/images/adsHome/image.png";
import Imga1 from "../../../assets/user/images/adsHome/image2.jpg";
import Imga2 from "../../../assets/user/images/adsHome/image6.jpg";
import Imga3 from "../../../assets/user/images/adsHome/image12.jpg";
import Imga4 from "../../../assets/user/images/adsHome/image8.jpg";
import Imga5 from "../../../assets/user/images/adsHome/image14.jpg";
import Imga6 from "../../../assets/user/images/adsHome/image1.png";
import Imga7 from "../../../assets/user/images/adsHome/image13.jpg";
import Imga8 from "../../../assets/user/images/adsHome/image7.jpg";
import Imga9 from "../../../assets/user/images/adsHome/image11.jpg";
import Imga10 from "../../../assets/user/images/adsHome/image4.jpg";
import Imga11 from "../../../assets/user/images/adsHome/image6.jpg";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

function BlogAds() {
  const navigate = useNavigate();
  const [key, setKey] = useState(10);
  const [showContactModal, setShowContactModal] = useState(false);

  useEffect(() => {
    document.title =
      "Kafood | TẠI SAO TRƯỜNG MẦM NON CẦN PHẢI SỬ DỤNG PHẦN MỀM KAFOOD?";
  }, []);

  const form = useRef();
  const formSDT = useRef();
  const sendSDT = (e) => {
    if (formSDT.current.phone.value.length > 9) {
      emailjs
        .sendForm(
          "service_fihg5vx",
          "template_es4vjjf",
          formSDT.current,
          "tt8EBINZkDg6uPMLi"
        )
        .then(
          () => {
            formSDT.current.phone.value = "";
            navigate("/dang-ky-thanh-cong");
            toast(`Đăng ký thành công`, {
              type: "success",
              autoClose: 1000,
              style: { marginTop: "50px" },
            });
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      toast(`Vui lòng nhập số điện thoại`, {
        type: "error",
        autoClose: 1000,
        style: { marginTop: "50px" },
      });
    }
  };

  const meta = {
    title: "Kafood | Phần mềm quản lý suất ăn và định lượng dinh dưỡng",
    description:
      "Kafood - Phần mềm quản lý suất ăn và định lượng dinh dưỡng trẻ mầm non. ",
    keywords:
      "Kafood, Phần mềm quản lý suất ăn, định lượng dinh dưỡng trẻ mầm non",
    og_image:
      process.env.REACT_APP_FRONTEND_URL +
      "/assets/user/images/product/detail/5_5.png",
    og_image_type: "image/png",
    // fb_app_id: '',
  };

  return (
    <div id="ads_home">
      <HeadMeta {...meta} />

      {showContactModal ? (
        <ContactModal
          show={showContactModal}
          onHide={() => setShowContactModal(false)}
        />
      ) : null}
      <Row style={{ background: "#f4f9fd" }}>
        <Col md={6}>
          <div className="image_top mt-3 ms-2">
            <img src={Imga} alt="Tại sao" />
          </div>
        </Col>
        <Col md={6} style={{ background: "#f4f9fd" }}>
          <div className="form-c">
            <center>
              <p className=" text-center text-gradient">
                Làm tất cả sổ sách báo cáo bán trú mầm non chỉ trong 5 phút. Sử
                dụng phần mềm quản lý mầm non Kafood ngay !
              </p>

              <ContactForm />
            </center>
          </div>
        </Col>
      </Row>

      <div className="cat-main-box">
        <div className="container py-4">
          <div className="row panel-row">
            <div className="title_home">
              <div className="text_title">
                <h1 >
                  Tại sao trường mầm non cần phải sử dụng phần mềm Kafood?
                </h1>
              </div>
            </div>

            <Row >
              <Col md={6}>
                <div className="text-conv">
                  <h2>TỰ ĐỘNG HÓA SỔ SÁCH BÁO CÁO</h2>
                  <div style={{fontSize:'18px'}}>
                  <FontAwesomeIcon icon={faCheck} size="" style={{color:'green'}} /> Tự động hóa quy trình lên thực đơn cân đối dinh dưỡng, tính
                    khẩu phần ăn hằng ngày và xuất các báo cáo sổ sách bán trú
                    như: Báo cáo dinh dưỡng ngày, tuần, tháng, sổ 3 bước, sổ lưu
                    mẫu, sổ công khai chi chợ…
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <img
                  src={Imga2}
                  alt="Kafood"
                  width="100%"
                  className="mt-1 img-conv"
                />
              </Col>
            </Row>
            <Row className="reverse-conv">
              <Col md={6}>
                <img
                  src={Imga1}
                  alt="Kafood"
                  width="100%"
                  className="mt-1 img-conv"
                />
              </Col>
              <Col md={6}>
                <div className="mt-3 text-conv">
                  <h2>QUẢN LÝ HỌC SINH VÀ CẮT SUẤT ĂN QUA ĐIỆN THOẠI</h2>
                  <div style={{fontSize:'18px'}}>
                      <FontAwesomeIcon icon={faCheck} size="" style={{color:'green'}} /> Quản lý danh sách học sinh, Chấm cơm, báo cáo sỉ số tự động
                  qua phần mềm, xuất báo cáo tính tiền ăn hoàn trả phụ huynh
                  chính xác theo giờ cắt suất ăn quy định của trường.
                  <br />
                      <FontAwesomeIcon icon={faCheck} size="" style={{color:'green'}} /> Kafood thay thế các công cụ thủ công như zalo và excel phải
                  tốn nhiều thời gian thống kê báo cáo cuối tháng.
                  </div>
                
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mt-3 text-conv">
                  <h2>NGÂN HÀNG MÓN ĂN</h2>
                  <div style={{fontSize:'18px'}}>
                      <FontAwesomeIcon icon={faCheck} size="" style={{color:'green'}} /> Ngân hàng món ăn có hơn một ngàn món giúp lên thực đơn nhanh
                  chóng và giúp trường tham khảo các món ăn mới cho bếp.
                  <br />
                      <FontAwesomeIcon icon={faCheck} size="" style={{color:'green'}} /> Định lượng món ăn dễ dàng ra phiếu đi chợ hàng ngày gửi nhà
                  cung cấp tránh sai sót lãng phí thức ăn.
                  </div>
                
                </div>
              </Col>
              <Col md={6}>
                <img
                  src={Imga3}
                  alt="Kafood"
                  width="100%"
                  className="mt-1 img-conv"
                />
              </Col>
            </Row>
            <Row className="reverse-conv">
              <Col md={6}>
                <img
                  src={Imga4}
                  alt="Kafood"
                  width="100%"
                  className="mt-1 img-conv"
                />
              </Col>
              <Col md={6}>
                <p className="mt-3 text-conv">
                  <h2>QUẢN LÝ KHO HÀNG VÀ NHÀ CUNG CẤP</h2>
                  <div  style={{fontSize:'18px'}}> 
                     <FontAwesomeIcon icon={faCheck} size="" style={{color:'green'}} /> Quản lý xuất nhập tồn hàng kho và vật tư, cảnh báo hết hạn sử
                  dụng.
                  <br />
                      <FontAwesomeIcon icon={faCheck} size="" style={{color:'green'}} /> Phiếu xuất kho, nhập kho chuẩn theo mẫu đúng quy định Bộ tài
                  chính.
                  <br />
                      <FontAwesomeIcon icon={faCheck} size="" style={{color:'green'}} /> Quản lý hàng hóa và nhà cung cấp thực phẩm xem lịch sử giá cả
                  thực phẩm lên xuống của các nhà cung cấp
                  </div>
                 
                </p>
              </Col>
            </Row>
            <Row >
              <Col md={6}>
                {" "}
                <p className="mt-3 text-conv">
                  <h2>CÓ APP QUẢN LÝ TỪ XA QUA ĐIỆN THOẠI VÀ MÁY TÍNH</h2>
                  <div  style={{fontSize:'18px'}}>
                      <FontAwesomeIcon icon={faCheck} size="" style={{color:'green'}} /> App sử dụng trên điện thoại cho giáo viên cắt suất ăn và được
                  phân quyền chức năng theo yêu cầu. <br />
                     <FontAwesomeIcon icon={faCheck} size="" style={{color:'green'}} /> Quản lý online 24/7 từ xa cho hiệu trường và chủ trường có hệ
                  thống nhiều trường.
                  </div>
                 
                </p>
              </Col>
              <Col md={6}>
                <img
                  src={Imga5}
                  alt="Kafood"
                  width="100%"
                  className="mt-1 img-conv"
                />
              </Col>
            </Row>
          </div>

          {/* <center className="mt-3">
            <Button
              onClick={() => setShowContactModal(true)}
              className="button-71"
            >
              Đăng ký dùng thử{" "}
            </Button>
          </center> */}
          <div className="mt-3">
            <h2 className="text-center">
              Hình ảnh triển khai xuống các trường mầm non
            </h2>

            <img src={Imga6} alt="Kafood" width="100%" className="mt-1" />
            <p
              style={{ fontStyle: "italic", fontSize: "16px" }}
              className="text-center mt-1"
            >
              Tập huấn trường mầm non Hạnh Phúc - Cà Mau sử dụng phần mềm Kafood
            </p>
            <img src={Imga7} alt="Kafood" width="100%" className="mt-1" />
            <p
              style={{ fontStyle: "italic", fontSize: "16px" }}
              className="text-center mt-1"
            >
              Hướng dẫn trường mầm non Quân Khu 9 - Cần Thơ
            </p>
            <img src={Imga8} alt="Kafood" width="100%" className="mt-1" />
            <p
              style={{ fontStyle: "italic", fontSize: "16px" }}
              className="text-center mt-1"
            >
              Hướng dẫn hệ thống mầm non Smiles Preschool áp dụng phần mềm
              Kafood
            </p>
            <img src={Imga9} alt="Kafood" width="100%" className="mt-1" />
            <p
              style={{ fontStyle: "italic", fontSize: "16px" }}
              className="text-center mt-1"
            >
              Hướng dẫn nhóm trẻ Hà Nguyễn sử dụng phần mềm Kafood
            </p>

            <h2 className="text-center">
              Video: đánh giá của hiệu trưởng- giáo viên khi sử dụng phần mềm
              Kafood
            </h2>
            <div className="video-container">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/4DCQxO-UfzM?si=nD0YVaUW-8FdLO7y"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
              ></iframe>
            </div>

            <h2 className="text-center mt-3">
              Phần mềm Kafood đạt giải nhì hội thi sáng tạo khoa học kỹ thuật
              lần thứ 12 năm 2022-2023 Thành phố Cần Thơ
              <img src={Imga10} alt="Kafood" width="100%" className="mt-1" />
            </h2>
            <p className="text-gradient text-center">
              Chi phí áp dụng phần mềm kafood vào quản lý mầm non chỉ từ
              150k/tháng. Đăng ký ngay!
            </p>
            <ContactForm />
            <p className="textCompany">
              Công ty cổ phần công nghệ Katec
              <br />
              Gọi ngay Hotline 0889 88 1010 - 0812 88 1010
            </p>
          </div>
        </div>
      </div>

      <div className="container "></div>
    </div>
  );
}

export default BlogAds;
