import React, { useEffect, useState } from 'react';
import { Button, Breadcrumb, Form, Col, Row } from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import DataTables from '../../other/DataTables';
import PostsAddModal from './PostsAddModal';
import PostsEditModal from './PostsEditModal';
import moment from 'moment';
import swal from 'sweetalert';

const PostsIndex = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Bài viết";
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getPostAll();
    }, [navigate]);

    const [posts, setPosts] = useState([]);
    const [showPostsAddModal, setShowPostsAddModal] = useState(false);
    const [showPostEditModal, setShowPostEditModal] = useState(false);
    const [idEdit, setIdEdit] = useState('');

    // const [selectedRows, setselectedRows] = useState([]);
    // const [alertShow, setalertShow] = useState(false);

    const getPostAll = async () => {
        await axios.get(`/allPost`).then((res) => {
            if (res.data.errCode === 0) {
                setPosts(res.data.posts);
            } else {
                toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            // toast(error.message, { type: 'error' });
        });
    }

    const editPost = (id) => {
        setShowPostEditModal(true);
        setIdEdit(id);
    }

    const deletePost = async (id, name) => {
        await swal({
            title: "Bạn muốn xóa Bài viết?",
            text: name,
            icon: "warning",
            buttons: ["Đóng", "Xóa"],
            dangerMode: true,
        }).then(async (ok) => {
            if (ok) {
                await axios.delete(`/post/destroy/${id}`).then((res) => {
                    if (res.data.errCode === 0) {
                        getPostAll();
                        toast(res.data.message, { type: 'success' });
                    } else {
                        toast(res.data.message, { type: 'error' });
                    }
                }).catch((error) => {
                    toast(error.message, { type: 'error' });
                });
            }
        })

    }

    const showPostAll = () => {
        const columns = [
            {
                name: "Tiêu đề",
                selector: (row) => row.titleVi,
                sortable: true,
            },
            {
                name: "Chủ đề",
                selector: (row) => row.topicNameVi,
                sortable: true,
            },
            {
                name: "Người đăng",
                selector: (row) => row.authName,
                sortable: true,
            },
            {
                name: "Trạng thái",
                selector: (row) => row.statusVi,
                sortable: true,
            },
            {
                name: "Cập nhật",
                selector: (row) => row.updatedAt,
                sortable: true,
                right: true,
                cell: row => <>{moment(row.updatedAt).format('DD/MM/YYYY hh:mm:ss')}</>
            },
            {
                name: "Phương thức",
                // selector: (row) => row.setting,
                center: true,

                cell: row => <>
                    <Button onClick={() => editPost(row.id)} variant='outline-warning' size="sm" className="me-2" type="button">
                        <FontAwesomeIcon icon={faPencil} />
                    </Button>

                    <Button onClick={() => deletePost(row.id, row.titleVi)} variant='outline-danger' size="sm" type="button">
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </>
            },
        ];

        const data = [];

        // const selectedRows = (items) => {
        //     setselectedRows(items);
        //     setalertShow(true);
        // };

        if (posts.length > 0) {
            posts.map((item, i) => {
                data.push({
                    id: item.id,
                    titleVi: item.titleVi,
                    topicNameVi: item.topicNameVi,
                    authName: item.authName,
                    statusVi: item.statusVi,
                    updatedAt: item.updatedAt,
                });
                return item;
            });
        }

        return (<>
            <Row className='bg-light p-2'>
                <Col md={3}>
                    <Form className="d-flex">
                        <Form.Control
                            type="search"
                            placeholder="Tìm kiếm"
                            className="me-2"
                            aria-label="Search"
                        />
                        <Button variant="outline-secondary" ><FontAwesomeIcon icon={faSearch} /></Button>
                    </Form>
                </Col>

                <Col md={4}>
                    <Button variant="outline-success" onClick={() => setShowPostsAddModal(true)}>
                        <FontAwesomeIcon icon={faPlus} /> Thêm
                    </Button>
                </Col>
            </Row>

            <DataTables data={data} columns={columns}
            // selectedRows={selectedRows} 
            />
        </>
        )
    }

    const onSubmitOk = () => {
        setShowPostsAddModal(false);
        setShowPostEditModal(false);
        getPostAll();
    }

    return (<>
        <div className='container'>
            <Breadcrumb className="p-2 bg-light">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/admin" }}>
                    Tổng quan
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Bài viết</Breadcrumb.Item>
            </Breadcrumb>
            {/* <RenderSelectedRows /> */}

            {showPostAll()}

            {showPostsAddModal ? <PostsAddModal show={showPostsAddModal} onHide={() => setShowPostsAddModal(false)} onSubmitSuccess={onSubmitOk} /> : <></>}
            {showPostEditModal && idEdit !== '' ? <PostsEditModal show={showPostEditModal} onHide={() => setShowPostEditModal(false)} idEdit={idEdit} onSubmitSuccess={onSubmitOk} /> : <></>}

        </div>
    </>
    )
}

export default PostsIndex;




