import { z } from "zod";

const phoneRegex =
  /^(?:\+84|0)(?:3[2-9]|5[2689]|7[06789]|8[1-9]|9[0-9])[0-9]{7}$/;

const schema = z.object({
  name: z
    .string()
    .max(50, { message: "Không vượt quá 50 kí tự" })
    .nonempty("Họ và tên không được để trống"),
  email: z
    .string()
    .max(120, { message: "Không vượt quá 120 kí tự" })
    .email("Địa chỉ email không hợp lệ"),
  phoneNumber: z
    .string()
    .nonempty("Số điện thoại không được để trống")
    .refine((value) => !value || phoneRegex.test(value), {
      message: "Số điện thoại không hợp lệ",
    }),
  address: z
    .string()
    .max(120, { message: "Không vượt quá 120 kí tự" })
    .nonempty("Địa chỉ không được để trống"),
  note: z.string().optional(),
});

export default schema;
