import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormatData from '../../other/FormatData';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import TextEditor from '../TextEditor';

const TopicEditModal = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        getTopic();
    }, [navigate]);

    const [slug, setSlug] = useState('');
    const [nameVi, setNameVi] = useState('');
    const [contentVi, setContentVi] = useState('');
    const [errors, setErrors] = useState([]);

    const id = props.idEdit || '';

    const [isLoading, setIsLoading] = useState(false);


    const getTopic = async () => {
        if (id !== '') {
            setIsLoading(true);
            await axios.get(`/topic/${id}`).then((res) => {
                if (res.data.errCode === 0) {
                    let topicData = res.data.topic;
                    setSlug(topicData.slug);
                    setNameVi(topicData.nameVi);
                    setContentVi(topicData.contentVi);
                    setIsLoading(false);
                } else {
                    toast(res.data.message, { type: 'error' });
                    setIsLoading(false);
                }
            }).catch((error) => {
                toast(error.message, { type: 'error' });
                setIsLoading(false);
            });
        }
    }

    const handleClose = () => props.onHide();

    const handleInputNameVi = (e) => {
        let value = e.target.value;
        // let field = e.target.name;

        let convertToSlug = FormatData.convertToSlug(value);

        let dataErrors = { ...errors };
        delete (dataErrors.slug);
        delete (dataErrors.nameVi);

        setNameVi(value);
        setSlug(convertToSlug);
        setErrors(errors);

    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        await swal({
            title: "Bạn muốn cập nhật chủ đề?",
            icon: "warning",
            buttons: ["Đóng", "Cập nhật"],
            successMode: true,
        }).then(async (ok) => {
            if (ok) {
                const data = {
                    nameVi: nameVi,
                    slug: slug,
                    contentVi: contentVi,
                };

                await axios.put(`/topic/update/${id}`, data).then((res) => {
                    if (res.data.errCode === 1) {
                        setErrors(res.data.messages);
                        toast('Cập nhật chủ đề thất bại.', { type: 'error' });
                    } else {
                        const { onSubmitSuccess } = { ...props };
                        onSubmitSuccess();

                        toast(res.data.message, { type: 'success' });
                    }
                }).catch((error) => {
                    toast(error.message, { type: 'error' });
                })
            }
        })
    }

    return (
        <>
            <Modal size='lg' show={props.show} onHide={handleClose} backdrop="static" >
                <Modal.Header closeButton>
                    <Modal.Title>Chi tiết chủ đề</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Floating className="mb-3">
                            <Form.Control type="text" onChange={(e) => handleInputNameVi(e)} name="nameVi" value={nameVi} className={errors.nameVi ? 'is-invalid' : ''} placeholder="Tên chủ đề" required />
                            <Form.Label>Tên chủ đề<i className="text-danger">*</i></Form.Label>
                            <span className='text-danger'>{errors.nameVi ? errors.nameVi : ''}</span>
                        </Form.Floating>

                        <Form.Floating className="mb-3">
                            <Form.Control type="text" name="slug" value={slug} className={errors.slug ? 'is-invalid' : ''} placeholder="Slug" readOnly />
                            <Form.Label>Slug<i className="text-danger">*</i></Form.Label>
                            <span className='text-danger'>{errors.slug ? errors.slug : ''}</span>
                        </Form.Floating>

                        <Form.Label>Mô tả</Form.Label>

                        {isLoading ? <>Đang tải...</> : <TextEditor setContents={contentVi} onChange={(value) => setContentVi(value)} placeholder="Mô tả" />}

                        <span className='text-danger'>{errors.contentVi ? errors.contentVi : ''}</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Đóng
                        </Button>
                        <Button variant="primary" type="submit">
                            Cập nhật
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default TopicEditModal;