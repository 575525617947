import React from 'react';
import DocumentMeta from 'react-document-meta';
import { useLocation } from 'react-router-dom';

const HeadMeta = (props) => {
    const isEmpty = Object.keys(props).length === 0;

    const reactUrl = process.env.REACT_APP_FRONTEND_URL + useLocation().pathname;
    const title = !isEmpty ? props.title : 'KAFOOD';
    const description = !isEmpty ? props.description : "Kafood - Phần mềm quản lý suất ăn và định lượng dinh dưỡng trẻ mầm non.";
    const keywords = !isEmpty ? props.keywords : "kafood, phần mềm báo ăn, phần mềm bán trú mầm non, quản lý bán trú mầm non,phần mềm quản lý mầm non, phần mềm dinh dưỡng mầm non, hệ thống phần mềm quản lý trường mầm non, phần mềm quản lý giáo dục mầm non, phần mềm quản lý bán trú mầm non, hệ thống quản lý phần mềm giáo dục mầm non, quản lý phần mềm giáo dục mầm non,phần mềm quản lý trường mầm non, phần mềm quản lý dinh dưỡng mầm non, quản lý mầm non phần mềm, phần mềm báo ăn mầm non, hệ thống phần mềm quản lý giáo dục mầm non,phần mềm quản lý nuôi dưỡng mầm non,phần mềm quản lý mầm non miễn phí,quan ly mầm non,phan mem quan ly mam non,đăng nhập phần mềm quản lý mầm non,phan mem giao duc mam non, phần mềm quản lý trường mầm non miễn phí, hồ sơ quản lý bán trú trường mầm non,quản lý phần mềm mầm non,các phần mềm ứng dụng trong giáo dục mầm non,phan mem quan ly truong mam non,phần mềm quản lý học sinh mầm non, phan mem mam non, phan mem quan ly dinh duong mam non, phần mềm quản lý thu chi trường mầm non,phần mềm thu học phí mầm non, phần mềm mầm non miễn phí, phần mềm quản lý nhà trẻ mẫu giáo fast, phan mem quan ly giao duc mam non, phần mềm bimbon,phần mềm quản lý trẻ mầm non, kafood"
    const og_image = !isEmpty ? props.og_image : '/favicon.ico';
    const og_image_type = !isEmpty ? props.og_image_type : '';
    // const fb_app_id = !isEmpty ? props.fb_app_id : '';

    const meta = {
        robots: "index, follow",
        title: title,
        description: description,
        canonical: reactUrl,
        meta: {
            charset: 'utf-8',
            title: title,
            copyright: 'Katec',
            author: 'Katec',
            name: {
                keywords: keywords,
                description: description,
                ['geo.region']: "VN",
                ['geo.placename']: "Cần Thơ",
                ['geo.position']: "10.041087; 105.7518469",
                ['geo.ICBM']: "10.041087, 105.7518469",
            },
            property: {
                ['og:locale']: 'vi',
                ['og:type']: 'article',
                ['og:url']: reactUrl,
                ['og:site_name']: 'Kafood',
                ['og:title']: title,
                ['og:description']: description,
                ['og:image']: og_image,
                ['og:image:type']: og_image_type,
                ['og:image:width']: 1200,
                ['og:image:height']: 630,
                // ['fb:app_id']: fb_app_id,
            }
        }
    };

    return (<DocumentMeta {...meta} />)
}

export default HeadMeta;