import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, FloatingLabel, Form, Image, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormatData from '../../other/FormatData';
import axios from 'axios';
import { toast } from 'react-toastify';
import TextEditor from '../TextEditor';

const PostsAddModal = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        getTopicAll();
    }, [navigate]);

    const [topics, setTopics] = useState([]);

    const [post, setPost] = useState({
        topicId: '',
        authId: localStorage.getItem('auth_id'),
        slug: '',
        keyword: '',
        image: '',
        titleVi: '',
        titleEn: '',
        descVi: '',
        descEn: '',
        // contentVi: '',
        // contentEn: '',
        statusId: ''
    });

    const [errors, setErrors] = useState([]);
    const [contentVi, setContentVi] = useState('');

    const getTopicAll = async () => {
        await axios.get(`/allTopic`).then((res) => {
            if (res.data.errCode === 0) {
                setTopics(res.data.topics);
            } else {
                toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            toast(error.message, { type: 'error' });
        });
    }

    const handleClose = () => props.onHide();

    const handleInput = (e) => {
        let value = e.target.value;
        let field = e.target.name;

        post[field] = value;
        delete (errors[field]);

        if (field === 'titleVi') {
            let convertToSlug = FormatData.convertToSlug(value);

            delete (errors.slug);

            post.slug = convertToSlug;
        }

        setPost({ ...post });
        setErrors({ ...errors });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            topicId: post.topicId,
            authId: post.authId,
            slug: post.slug,
            keyword: post.keyword,
            image: post.image,
            titleVi: post.titleVi,
            titleEn: post.titleEn,
            descVi: post.descVi,
            descEn: post.descEn,
            contentVi: contentVi,
            contentEn: '',
            statusId: post.statusId,
        };

        await axios.post(`/post/create`, data).then((res) => {
            if (res.data.errCode === 1) {
                setErrors(res.data.messages);

                toast('Thêm bài viết thất bại.', { type: 'error' });
            } else {
                const { onSubmitSuccess } = { ...props };
                onSubmitSuccess();

                toast(res.data.message, { type: 'success' });
            }
        }).catch((error) => {
            toast(error.message, { type: 'error' });
        });
    }

    const optionTopics = () => {

        if (topics.length > 0) {
            return topics.map((item, key) => {
                return <option key={`topicOption${key}`} value={item.id}>{item.nameVi}</option>
            })
        }
    }

    const handleChange = (value) => {
        delete (errors.contentVi);
        setErrors({ ...errors });
        setContentVi(value);
    }

    return (
        <>
            <Modal size='lg' show={props.show} onHide={handleClose} backdrop="static" >
                <Modal.Header closeButton>
                    <Modal.Title>Thêm bài viết</Modal.Title>
                </Modal.Header>

                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Row>
                            <Col md={6}>
                                <FloatingLabel label={<>Chủ đề<i className="text-danger">*</i></>}>
                                    <Form.Select onChange={(e) => handleInput(e)} name="topicId" defaultValue={post.topicId} className={errors.topicId ? 'is-invalid' : ''}>
                                        <option value="">-- Chọn --</option>
                                        {optionTopics()}
                                    </Form.Select>

                                    <span className='text-danger'>{errors.topicId ? errors.topicId : ''}</span>

                                </FloatingLabel>
                            </Col>

                            <Col md={6}>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" value={localStorage.getItem('auth_full_name')} readOnly />
                                    <Form.Label>Người đăng</Form.Label>
                                </Form.Floating>
                            </Col>
                        </Row>

                        <Form.Floating className="mb-3">
                            <Form.Control type="text" onChange={(e) => handleInput(e)} name="titleVi" value={post.titleVi} className={errors.titleVi ? 'is-invalid' : ''} placeholder="Tên chủ đề" />
                            <Form.Label>Tiêu đề<i className="text-danger">*</i></Form.Label>
                            <span className='text-danger'>{errors.titleVi ? errors.titleVi : ''}</span>
                        </Form.Floating>

                        <Form.Floating className="mb-3">
                            <Form.Control type="text" name="slug" value={post.slug} className={errors.slug ? 'is-invalid' : ''} placeholder="Slug" readOnly />
                            <Form.Label>Slug<i className="text-danger">*</i></Form.Label>
                            <span className='text-danger'>{errors.slug ? errors.slug : ''}</span>
                        </Form.Floating>

                        <Row>
                            <Col md={6}>
                                <Form.Floating className="mb-3">
                                    <Form.Control type="text" onChange={(e) => handleInput(e)} name="image" value={post.image} className={errors.image ? 'is-invalid' : ''} placeholder="Link hình ảnh" />
                                    <Form.Label>Link hình ảnh</Form.Label>
                                    <span className='text-danger'>{errors.image ? errors.image : ''}</span>
                                </Form.Floating>

                            </Col>

                            <Col md={4}>
                                <Image className='mb-3' src={post.image} thumbnail height={'60px'} />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <FloatingLabel className='mb-3' label="Từ khóa">
                                    <Form.Control
                                        as="textarea"
                                        onChange={(e) => handleInput(e)}
                                        value={post.keyword}
                                        name="keyword"
                                        placeholder="Từ khóa"
                                        style={{ height: '100px' }}
                                    />
                                    <span className='text-danger'>{errors.keyword ? errors.keyword : ''}</span>
                                </FloatingLabel>


                            </Col>

                            <Col md={6}>
                                <FloatingLabel className='mb-3' label="Mô tả">
                                    <Form.Control
                                        as="textarea"
                                        onChange={(e) => handleInput(e)}
                                        value={post.descVi}
                                        name="descVi"
                                        placeholder="Mô tả"
                                        style={{ height: '100px' }}
                                    />
                                    <span className='text-danger'>{errors.descVi ? errors.descVi : ''}</span>
                                </FloatingLabel>

                            </Col>
                        </Row>

                        <Form.Label>Nội dung<span className='text-danger'>*</span></Form.Label>

                        <TextEditor setContents={contentVi} onChange={handleChange} placeholder="Nội dung" />

                        <span className='text-danger'>{errors.contentVi ? errors.contentVi : ''}</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Đóng
                        </Button>
                        <Button variant="primary" type="submit">
                            Lưu
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default PostsAddModal;