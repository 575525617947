import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { Col, Row, Card, Breadcrumb } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import "./blog.scss";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState } from 'react';

const BlogIndex = () => {
    useEffect(() => {
        if (posts.length === 0) {
            document.title = "Kafood | Góc chia sẻ";
            window.scrollTo({ top: 0, behavior: 'smooth' });
            getPostAll();
        }
    });

    const [posts, setPosts] = useState([]);

    const getPostAll = async () => {
        await axios.get(`/getPostsByTopics/all`).then((res) => {
            if (res.data.errCode === 0) {
                setPosts(res.data.posts?.filter(
                    post=>post.slug!='chinh-sach'
                ));
            } else {
                // toast(res.data.message, { type: 'error' });
            }
        }).catch((error) => {
            // toast(error.message, { type: 'error' });
        });
    }

    const viewPosts = () => {
        let output = [];

        if (posts.length > 0) {
            for (let i = 0; i < posts.length; i++) {
                let listPosts = posts[i].posts;
                let outputPosts = [];

                for (let j = 0; j < listPosts.length; j++) {
                    outputPosts[j] = (
                        <Col md={j === 2 || j === 3 ? 6 : 3} key={`postItem${listPosts[j].id}`}>
                            <Card >
                                <Card.Img variant="top" src={listPosts[j].image} />

                                <Card.Body>
                                    <Card.Title>
                                        <Link to={`/bai-viet/${listPosts[j].slug}`}>
                                            {listPosts[j].titleVi}
                                        </Link>
                                    </Card.Title>
                                    <Card.Text>
                                        {listPosts[j].descVi}
                                    </Card.Text>

                                    <div className='cart-reviews'>
                                        <Card.Link href="#">
                                            <FontAwesomeIcon icon={faThumbsUp} />
                                        </Card.Link>
                                        <Card.Link href="#">
                                            <FontAwesomeIcon icon={faThumbsDown} />
                                        </Card.Link>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col >
                    )
                }


                output[i] = (
                    <div className="container p-lg-4 pb-md-0 bg-light mb-4" key={`topicItem${i}`}>
                        <Row >
                            <Col md={12} className='pb-2'>
                                <h2 className="blog-topic-name">{posts[i].nameVi}</h2>
                            </Col>

                            {outputPosts}

                            <Col md={12} className='text-end pb-3 pb-md-0'>
                                <Link to={`/chu-de/${posts[i].slug}`} className='see-more btn btn-outline-primary'>Xem thêm &#8594;</Link>
                            </Col>
                        </Row>
                    </div >
                );
            }
        }

        return output;

    }

    return (
        <div className='detail_bg' id="page_blog">
            <div className='text_title'>
                <h1>GÓC CHIA SẺ</h1>
            </div>

            <div className="container pt-3 pb-2 pb-md-0 ps-md-4 bg-light">
                <Breadcrumb >
                    <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: "/" }}>
                        Trang chủ
                    </Breadcrumb.Item>
                    <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: "/goc-chia-se" }}>
                        Góc chia sẻ
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>

            {viewPosts()}
        </div >
    )
}

export default BlogIndex;